
const INITIAL_STATE = {
    headoffices: [ ],

};

const HeadOfficeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_HEADOFFICE':
            return {
                ...state,
                headoffices: action.payload
            };

        default:
            return state;
    }
};

export default HeadOfficeReducer;
