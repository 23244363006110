import React from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from 'react';
import * as columnFormatters from "../../modules/ECommerce/pages/products/products-table/column-formatters";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import firebase from 'firebase';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';


const DataTable = ({ list }) => {

    const history = useHistory()

    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setdeleteModal] = useState(false)
    const [dispatchModal, setdispatchModal] = useState(false)
    const [blockModal, setblockModal] = useState(false)
    const [selectedRoles, setSelectedRoles] = React.useState([])
    const [selectedObj, setselectedObj] = useState({})

    const [loading, setLoading] = useState(false)

    const [title, settitle] = React.useState(selectedObj.title)


    const getTime = (timestamp) => {
        let dd = new Date(timestamp)

        let newd = new Intl.DateTimeFormat('en-us', {
            timeStyle: 'medium'
        })

        return newd.format(dd)
    }
    const getDate = (timestamp) => {
        let dd = new Date(timestamp)

        let newd = new Intl.DateTimeFormat('en-us', {
            dateStyle: 'medium'
        })

        return newd.format(dd)
    }


    const columns = [
        {
            dataField: "title",
            text: "Incident",
            sort: true,

        },
        // {
        //     dataField: "description",
        //     text: "Description",
        //     sort: true,

        // },
        {
            dataField: "location",
            text: "Location",
            sort: true,

        },

        {
            dataField: "timestamp",
            text: "Date",
            sort: true,
            formatter: (timestamp) => {
                return (
                    <span
                    // className={`label label-lg label-inline`}
                    >
                        {getTime(timestamp)}
                    </span>
                )
            }

        },
        {
            dataField: "timestamp",
            text: "Time",
            sort: true,
            formatter: (timestamp) => {
                return (
                    <span
                    // className={`label label-lg label-inline`}
                    >
                        {getDate(timestamp)}
                    </span>
                )
            }

        },

        {
            dataField: "action",
            text: "Actions",
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditProductPage: (item) => openEditModal(item),
                openDeleteProductDialog: (row) => openDeleteModal(row),
                openDispatch: (row) => openDispatchModal(row),
                onViewClick: (row) => history.push(`/view/${row.key}`),
                onBlockClick: (row) => openBlockModal(row),
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];


    const openDispatchModal = (item) => {
        console.log(item);
        setselectedObj(item)
        setdispatchModal(true)
    }


    const openEditModal = item => {
        setselectedObj(item)
        setEditModal(true)
        // console.log(item)
    }

    const openDeleteModal = item => {
        setselectedObj(item)
        setdeleteModal(true)
        // console.log(item)
    }


    const openBlockModal = item => {
        setselectedObj(item)
        setblockModal(true)
    }


    const updateInc = () => {

        firebase.database().ref('incidents')
            .child(selectedObj.key)
            .update({
                title: title
            }).then(() => {
                toast.success('Updated Successfully')
                setEditModal(false)
            }).catch(err => {
                console.log(err)
                setEditModal(false)
            })

    }

    const deleteInc = () => {
        firebase.database().ref('incidents')
            .child(selectedObj.key)
            .remove()
            .then(() => {
                toast.success('Delete Successfully')
                setdeleteModal(false)
            }).catch(err => {
                console.log(err)
                setdeleteModal(false)
            })
    }


    const selectRoles = role => {
        let arr = [...selectedRoles]
        if (!arr.includes(role)) {
            arr.push(role)
        }

        setSelectedRoles(arr)
    }




    const removeRoles = role => {

        const filre = selectedRoles.filter(item => item !== role)
        setSelectedRoles(filre)
        // console.log(filre)

    }




    const DispatchToDepart2 = () => {

        setLoading(true)

        selectedRoles.map((role => {

            firebase.database().ref('users')
                .orderByChild('role')
                .equalTo(role)
                .on('value', snapshot => {
                    if (snapshot.exists()) {
                        let array = [];
                        let item;
                        snapshot.forEach((value) => {
                            item = value.val();
                            item.key = value.key;
                            array.push(item);
                        })
            
                        let user = array[0]
                        let pushkey = firebase.database().ref('dispatchData').push().key
                        firebase.database().ref('dispatchData')
                            .child(user.key)
                            .child(pushkey)
                            .set({
                                userId: selectedObj.userId,
                                type: selectedObj.title,
                                incidentId: selectedObj.incidentId,
                                // userEmail: firebase.auth().currentUser.email,
                                latitude: selectedObj.latitude,
                                location: selectedObj.location,
                                longitude: selectedObj.longitude,
                                timestamp: Date.now()
                            }).then(() => {
                                if (user.token) {
                                    setdispatchModal(false)
                                    setLoading(false)

                                    sendNotification(user.token, "dispatch")
                                }
                            }).catch(err => {
                                toast.error(err)
                                setLoading(false)

                            })
                    }
                    else {
                        console.log('not found')
                        setLoading(false)

                    }
                })
        }))



    }


    const sendNotification = async (token, type) => {

        console.log(token);
        const notification = {
            body: 'You have new Incident',
            title: `Dispatch By Admin for ${selectedObj.title}`,
        }

        const body = {
            body: 'You have new Incident',
            title: `Dispatch By Admin for ${selectedObj.title}`,
            type: type,
        }
        const fdata = {
            notification: notification,
            data: body,
            to: token,

        }
        await fetch("https://fcm.googleapis.com/fcm/send", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "key=AAAALKol5GU:APA91bFdo5oss619rrxshAX-fVCYJJ5IyM4mt3AcvCMA_Hakzp6aRd8LmDVYGnfre5zxaPxWnQ9ewB3oI7rAfe8eh4gCSEYfq9R-3m7xoxdCXcLZUsfI84mJS_zIlBg1SLsP1Y55dcyj",
            },
            method: "POST",
            body: JSON.stringify(fdata),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("response of notifications===>", data);
                toast.success('Incident Dispatched to' + selectedObj.title)
                setSelectedRoles([])
                setdispatchModal(false)
                setLoading(false)
                // setOpen(false)
            })
            .catch((error) => {
                console.log("notification error====>", error);
                setLoading(false)

            });
    }




    const blockUser = () => {
        firebase.database().ref('blockedUsers')
            .child(selectedObj.userId)
            .set({
                isBlocked: true
            }).then(() => {
                toast.success('User blocked')
                setblockModal(false)
            })
    }




    return (
        <div>
            <Card>
                <CardHeader title="Incidents List">

                </CardHeader>
                <CardBody>

                    <BootstrapTable
                        wrapperClasses="table-responsive"
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        bordered={false}
                        remote
                        keyField="key"
                        data={list === null ? [] : list}
                        columns={columns}
                    >
                    </BootstrapTable>
                </CardBody>
            </Card>


            <Modal
                show={editModal}

            >
                <Modal.Header>
                    <Modal.Title>Edit Incident</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select
                        defaultValue={selectedObj.title}
                        onChange={(e) => settitle(e.target.value)}
                        className='form-control'
                    >
                        <option value={'floods'}>Floods</option>
                        <option value={'fires'}>Fires</option>
                        <option value={'typhoons'}>Typhoons</option>
                        <option value={'accidents'}>Accidents</option>
                        <option value={'landslides'}>Landslides</option>
                        <option value={'police'}>Police Report</option>
                        <option value={'Strom surge'}>Strom Surge</option>
                        <option value={'Tsunami'}>Tsunami</option>
                        <option value={'Drought'}>Drought</option>
                        <option value={'Medical Emergency'}>Medical Emergency</option>
                        <option value={'earthquake'}>Earthquake</option>
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setEditModal(false)}
                        className='btn btn-danger'>Cancel</button>
                    <button
                        onClick={updateInc}
                        className='btn btn-primary'>Update</button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}

            >
                <Modal.Header>
                    <Modal.Title>Delete Incident</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this incident?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setdeleteModal(false)}
                        className='btn btn-danger'>Cancel</button>
                    <button
                        onClick={deleteInc}
                        className='btn btn-primary'>Delete</button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={dispatchModal}

            >
                <Modal.Header>
                    <Modal.Title>Dispatch Incident</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            // ['thunnustamaraw', 'thunnustalabasi', 'newmahalta', 'calapancity','socorroeagles'].map(pers => (
                                ['ncr', 'mimaropa', 'calabarzon', 'ilocosregion','centralluzon'].map(pers => (
                                selectedRoles.includes(pers) ?
                                    <div
                                        onClick={() => removeRoles(pers)}
                                        style={{ cursor: 'pointer', margin: 5, borderRadius: 6, border: '1px solid white', padding: 7, backgroundColor: 'red' }}>
                                        <h4 style={{ color: 'white' }}>{pers == 'admin' ? 'MDRRMO' : pers.toLocaleUpperCase()}</h4>
                                    </div>
                                    :
                                    <div
                                        onClick={() => selectRoles(pers)}
                                        style={{ cursor: 'pointer', margin: 5, borderRadius: 6, border: '1px solid #eeee', padding: 7, backgroundColor: '#eeee' }}>
                                        <h4>{pers == 'admin' ? 'MDRRMO' : pers.toLocaleUpperCase()}</h4>
                                    </div>
                            ))
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Spinner animation='border' />
                            </div>
                            :
                            <>

                                <button
                                    onClick={() => setdispatchModal(false)}
                                    className='btn btn-danger'>Cancel</button>
                                <button
                                    onClick={DispatchToDepart2}
                                    className='btn btn-primary'>Dispatch</button>

                            </>
                    }
                </Modal.Footer>
            </Modal>


            <Modal
                onHide={() => setblockModal(true)}
                show={blockModal}
            >
                <Modal.Header>
                    <Modal.Title>Block User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to Block this user?
                </Modal.Body>

                <Modal.Footer>
                    <button
                        onClick={() => setblockModal(false)}
                        className='btn btn-danger' >Cancel</button>
                    <button
                        onClick={blockUser}
                        className='btn btn-primary'>Block</button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}

export default DataTable