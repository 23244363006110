
const INITIAL_STATE = {

    vehicles: []

};

const VehiclesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_VEHICLES':
            return {
                ...state,
                vehicles: action.payload,


            };

        default:
            return state;
    }
};

export default VehiclesReducer;
