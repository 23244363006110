import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import { ArrowBackIos } from '@material-ui/icons'
import {Grid, Card, CardContent, CardMedia, Typography, CardActionArea } from '@material-ui/core';




const ViewAll = () => {

    const [incidentsList, setincidentsList] = useState([])


    useEffect(() => {
        firebase.database().ref('incidents')
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    let arr = []
                    snapshot.forEach((value) => {
                        let item = value.val();
                        item.key = value.key;
                        arr.push(item);
                    })
                    setincidentsList(arr)
                }
            })
    }, [])




    return (
        <div style={{ padding: 10 }}>

            <Link to={'/'}>
                <ArrowBackIos
                    // onClick={() =>}
                    style={{ cursor: 'pointer' }} className='text-center' />
            </Link>
            <Grid marginTop={2} container spacing={4}
            >
                {
                    incidentsList && incidentsList.map(item => (
                        <CardAction
                            key={item.key}
                            id={item.key}
                            title={item.title}
                            image={item.image}
                            description={item.description}

                        />
                    ))
                }
            </Grid>

        </div>
    )
}

export default ViewAll

 function CardAction({ id, title, description, image }) {
    console.log(id)
    return (
        <Grid item md={3}>
            <Link to={`/view/${id}`}>
                <Card sx={{ maxWidth: 345, }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={image}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {description}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        </Grid>
    );
}