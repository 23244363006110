import React from 'react'
import './style.css'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Link, } from 'react-router-dom';
import markergif from '../../assets/testgif.gif'


const containerStyle = {
    width: '100%',
    height: 600
};

// const center = {
//     lat: 31.3825843,
//     lng: 73.0783944
// };
const center = {
    lat: 12.304858,
    lng: 121.193859
};


const CustomMap = ({ lat, lng, list }) => {

    // const navigate = useNavigate();


    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAwElRh57Tzj0OJJDr1VzLGXF1Xm6pcnUg"
        >
            <GoogleMap
                mapTypeId={'hybrid'}

                onClick={(e) => console.log(e.latLng.toJSON())}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={6}
            >

                <>
                    {
                        list.map((marker, i) => (
                            new Date(marker.timestamp).getMinutes() == new Date().getMinutes() ?
                                <Marker
                                    // onClick={() => navigate(`/view/${marker.key}`)}
                                    key={i + 1}
                                    title={marker.description}
                                    position={{
                                        lat: marker.latitude,
                                        lng: marker.longitude
                                    }}
                                    icon={{
                                        url: markergif,
                                        scaledSize: new window.google.maps.Size(100, 100)

                                    }}
                                />
                                : null




                        ))
                    }
                </>
            </GoogleMap>
        </LoadScript >
    )
}

export default CustomMap