import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable/DataTable'

const IncidentList = () => {

  const [incidentsList, setincidentsList] = useState([])


  useEffect(() => {

    firebase.database().ref('incidents')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          let arr = []
          snapshot.forEach((value) => {
            let item = value.val();
            item.key = value.key;
            arr.push(item);
          })
          // fireAlarm(arr)
          setincidentsList(arr)

        }
      })


  }, [])


  return (
    <div>
      <DataTable list={incidentsList} />
    </div>
  )
}

export default IncidentList