/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import firebase from "firebase";
import { Modal } from 'react-bootstrap'
import { toast } from "react-hot-toast";



export function HeaderMenu({ layoutProps }) {


    const [modalVisible, setModalVisible] = useState(false)
    const [tokensList, settokensList] = useState([])


    const [title, settitle] = React.useState('')
    const [body, setbody] = React.useState('')

    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }



    useEffect(() => {

        firebase.database().ref('tokens')
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    let arr = []
                    snapshot.forEach((value) => {
                        let item = value.val();
                        // item.key = value.key;
                        arr.push(item);
                    })
                    const finalList = arr.map(item => item.token)
                    settokensList(finalList)
                }
            })

    }, [])



    const sendNotification = () => {

        if (title == "" || body == "") {

            toast.error('Please enter a title and body')
        } else {

            tokensList.map((token, index) => {

                const notification = {
                    body: body,
                    title: title,
                }

                fetch(`https://fcm.googleapis.com/fcm/send`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `key=AAAALKol5GU:APA91bFdo5oss619rrxshAX-fVCYJJ5IyM4mt3AcvCMA_Hakzp6aRd8LmDVYGnfre5zxaPxWnQ9ewB3oI7rAfe8eh4gCSEYfq9R-3m7xoxdCXcLZUsfI84mJS_zIlBg1SLsP1Y55dcyj`
                    },
                    body: JSON.stringify({
                        notification,
                        to: token
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        setModalVisible(false)
                        // console.log(index)
                        // console.log(tokensList.length + 1)
                        // if (index == tokensList.length + 1) {
                        //     setModalVisible(false);
                        //     console.log('end')
                        // }
                        // toast.success('Message sent successfully')
                    }).catch(err => {
                        console.log(err)
                    })
            })

            setTimeout(() => {
                toast.success('Message sent successfully')
            }, 2000);


        }
    }


    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>
                <NavLink
                    to={'#'}
                    className="menu-link menu-toggle"
                    onClick={() => setModalVisible(true)}
                >
                    <span className="menu-text">Send Notification</span>
                    <i className="menu-arrow"></i>
                </NavLink>

            </li>

        </ul>


        <Modal show={modalVisible}>
            <Modal.Header>
                <Modal.Title>Send Notification to All</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <input
                    onChange={e => settitle(e.target.value)}
                    className="form-control m-2"
                    placeholder="title" />

                <textarea
                    onChange={e => setbody(e.target.value)}
                    className="form-control m-2"
                    placeholder="description" />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setModalVisible(false)} className="btn btn-danger">Cancel</button>
                <button onClick={sendNotification} className="btn btn-primary">Send</button>
            </Modal.Footer>
        </Modal>
    </div>;
}
