import React, { useEffect, useState } from 'react'
import './style.css'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, useParams } from 'react-router-dom';
import firebase from 'firebase';

const containerStyle = {
    width: '350px',
    height: '350px'
};



const ViewIncident = () => {

    const params = useParams()

    const center = { lat: 31.61172954302461, lng: 74.2831559561035 }
    const [details, setdetails] = useState({})

    useEffect(() => {
        getDetails()
    }, [])


    const getDetails = () => {
        const { id } = params

        firebase.database().ref('incidents')
            .child(id)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    setdetails(snapshot.val())

                }

            })


    }


    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAwElRh57Tzj0OJJDr1VzLGXF1Xm6pcnUg"
        >

            <div className="blog-single gray-bg">
                <Link to={'/'}>
                    <ArrowBackIosIcon
                        // onClick={() =>}
                        style={{ marginLeft: 20, cursor: 'pointer' }} className='text-center' />
                </Link>
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-lg-8 m-15px-tb">
                            <article className="article">
                                <div className="article-img">
                                    <img src={details.image} title="" alt="" />
                                </div>
                                <div className="article-title">

                                    <h2>{details.title}</h2>

                                </div>
                                <div className="article-content">
                                    <p>{details.description}</p>


                                </div>

                            </article>

                        </div>
                        <div className="col-lg-4 m-15px-tb blog-aside">

                            <div className="widget widget-author">

                                <GoogleMap
                                    // onClick={(e) => console.log(e.latLng.toJSON())}
                                    mapContainerStyle={containerStyle}
                                    center={{
                                        lat: details.latitude,
                                        lng: details.longitude
                                    }}
                                    zoom={10}
                                >

                                    <Marker
                                        title={'title'}
                                        position={{
                                            lat: details.latitude,
                                            lng: details.longitude
                                        }}
                                    />

                                </GoogleMap>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </LoadScript>
    )
}


export default ViewIncident