import React, { useState } from 'react'
import { Paper } from '@material-ui/core'

import firebase from 'firebase';
import { toast } from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import './styless.css'
import ReactToPrint from "react-to-print";
import logoM from './printimg.jpeg'

// const logoM = `https://cdn-icons-png.flaticon.com/512/748/748113.png`

const IncidentList = [
    {
        id: 9,
        title: 'Fire',
        value: 'fire'
    },
    {
        id: 3,
        title: 'Police',
        value: 'police'
    },
]
const pageStyle = `
  @page {
    size: landscape !important;
}
`;


const NewReportView = () => {


    const onBeforeGetContentResolve = React.useRef(null);
    const componentRef = React.useRef()
    const params = useParams()


    const [incidentObj, setincidentObj] = useState({})
    const [imgList, setimgList] = useState([])
    const { id } = params

    useEffect(() => {
        firebase.database().ref('newIncidents')
            .child(id)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    setincidentObj(snapshot.val())
                    const lis = Object.values(snapshot.val().imagesList)

                    setimgList(lis)
                }
            })
    }, [])


    const [printloading, setprintloading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");


    const [imagesList, setimagesList] = useState([])


    const initialValues = {
        incidentNumber: incidentObj.incidentNumber,
        incidentType: incidentObj.incidentType,
        others: incidentObj.others,
        locationofincident: '',
        dateandtime: '',
        involvedandplatenumber: '',
        natureofincident: '',
        informantName: '',
        timeofcall: '',
        numberofpatient: '',
        nameofpatient: '',
        age: '',
        civilStatus: '',
        address: '',
        timedeployed: '',
        timedepartureOpcen: '',
        timearrivalOpcen: '',
        timearrivalLocation: '',
        timedepartureLocation: '',
        actiontakenbyteam: '',
        teamleader: '',
        documenter: '',
        logistics: '',
        communication: '',
        driver: '',
        otherresponder: '',
        vehicleUsed: '',
        othervehicleUsed: '',
        description: '',
        preparedby: '',
        submittedby: '',
        receivedby: '',
        notedby: ''

    }



    const [currentStep, setcurrentStep] = useState(1)
    const [loading, setLoading] = useState(false)



    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: initialValues,

        onSubmit: (values) => {


            console.log(values)
            // setLoading(true)
            // firebase.database().ref('newIncidents')
            //     .push(values)
            //     .then(() => {
            //         setLoading(false)
            //         toast.success(`Incident Reported successfully`)
            //     }).catch(err => {
            //         setLoading(false)
            //         toast.error(err.message)
            //     })
        }
    });


    //for print
    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setprintloading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setprintloading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setprintloading, setText]);

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");
    }, []);

    const gotToNext = () => {
        setcurrentStep(currentStep + 1)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const gotToPrevious = () => {
        setcurrentStep(currentStep - 1)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }



    return (
        <Paper style={{
            padding: 15,
        }}>
            <div className='col-md-5'>

                <ReactToPrint
                    document
                    Title={`incident Report`}
                    // pageStyle={pageStyle}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                        <button

                            disabled={printloading ? true : false}
                            style={{ marginLeft: "10px" }}
                            className="btn btn-success"
                        >
                            {printloading ? <span>Printing..</span> : "Print"}
                        </button>
                    )}
                    content={() => componentRef.current}
                />

            </div>

            <div

            >
                {/* <h1 className='text-center'>Report Incident</h1> */}
                <div className='row col-md-12'
                    style={{
                        padding: 5
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '0px auto',

                        }}>
                        <div style={{
                            display: 'flex',
                            margin: 10
                            // alignItems:'center',
                            // justifyContent:'center'
                        }}>
                            <div>
                                <img style={{ margin: 10 }} height={80} src={logoM} />
                            </div>
                            {/* <div className='ml-2'>
                                <p>Province of Occidental Mindoro</p>
                                <p>Municipality of Magsaysay</p>
                                <b>Municipal Disaster Risk Reduction and Managment Office</b>
                            </div> */}

                        </div>

                    </div>
                    {/* form one  */}

                    <>
                        <div className='form1'>
                            <h3 style={{ marginLeft: 300, padding: 10 }}>Incident Information</h3>
                            <div className='row d-flex m-1'>
                                <div className='col-md-3'>
                                    <label>
                                        Incident No:
                                    </label>
                                </div>
                                <div className='col-md-5 line-bor' style={{ marginLeft: 3 }}>
                                    {incidentObj.incidentNumber}

                                </div>

                            </div>
                            {/* <div className='row'> */}
                            {/* <div className='col-md-12 d-flex'> */}
                            <div className='row d-flex m-1'>
                                <div className='col-md-3'>
                                    <label>
                                        Name/Type of Incident:
                                    </label>

                                </div>
                                <div className='col-md-5 line-bor' style={{ marginLeft: 3 }}>
                                    {incidentObj.incidentType}
                                </div>
                            </div>

                            {/* </div> */}

                            {/* </div> */}
                            <div className='row d-flex m-1'>
                                <div className='col-md-3'>
                                    <label>
                                        Other (Please Indicate):
                                    </label>
                                </div>
                                <div className='col-md-5 line-bor' style={{ marginLeft: 3 }}>
                                    {incidentObj.others}
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Place/Location of Incident:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>

                                        {incidentObj.locationofincident}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Date and time of Indcident
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.dateandtime}
                                    </div>
                                </div>

                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            If Vehicular Accident, state the number and type of vehicle involved and plate number:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.involvedandplatenumber}


                                    </div>
                                </div>


                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            If Other Incident, state the nature of Incident:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.natureofincident}
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Name of Informant/Caller:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.informantName}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time of call/Reported:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.timeofcall}

                                    </div>

                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Number of Patient/Casualty(if any):
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.numberofpatient}



                                    </div>
                                </div>

                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Name/s of Patient/Casualty(if any):
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor p-3'>
                                        {incidentObj.nameofpatient}
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Age:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.age}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Civil Status:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.civilStatus}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Address:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.address}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='newform3'>
                            <h3 style={{ marginLeft: 200, padding: 15 }}>Emergency/Quick Response Team Deployment</h3>

                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Time Deployed:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.timedeployed}
                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Time of Departure (Opcen):
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.timedepartureOpcen}
                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Time of Arrival (Location):
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>

                                    {incidentObj.timearrivalLocation}



                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Time of Departure (Location):
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.timedepartureLocation}



                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Time of Arrival (OpCen):
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.timearrivalOpcen}

                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Action taken by team:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.actiontakenbyteam}


                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Team Leader:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.teamleader}



                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Documenter:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.documenter}

                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Logistics:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.logistics}



                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Communication:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.communication}



                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Driver:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.driver}


                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Other responder on Scene:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.otherresponder}
                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Vehicle Used:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.vehicleUsed}



                                </div>
                            </div>
                            <div className='col-md-11 d-flex m-1'>
                                <div className='col-md-5'>
                                    <label>
                                        Other Vehicle Used:
                                    </label>
                                </div>
                                <div className='col-md-8 line-bor'>
                                    {incidentObj.othervehicleUsed}


                                </div>
                            </div>

                        </div>

                    </>

                    <div className='container'>
                        <div style={{ placeContent: 'center' }} className='row'>
                            {
                                imgList && imgList.map(img => (
                                    <div style={{ border: '1px solid black', }} className='col-md-5 m-1'>
                                        <img style={{ height: 200, margin: 5 }} src={img} />
                                    </div>
                                ))

                            }
                        </div>


                    </div>


                    <>
                        <div className='desc-box'>
                            <div className='desc-box-inner'>
                                DESCRIPTION OF INCIDENT
                            </div>

                            <div className='descip'>
                                <h6 className='descri-text'>{incidentObj.description}</h6>
                            </div>
                        </div>
                        <div style={{ marginLeft: 200 }}>
                            <div style={{ margin: 10 }} className='d-flex'>
                                <div className='col-md-10 d-flex justify-content-around'>
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30 }}>Prepared by:</p>
                                        <div >
                                            <h5 className='line-bor2'>{incidentObj.preparedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>Responder on Duty</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30 }}>Submited by:</p>
                                        <div >
                                            <h5 className='line-bor2 mb-3'>{incidentObj.submittedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>Team leader</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ margin: 10 }} className='row'>
                                <div className='col-md-10 d-flex justify-content-around'>
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30 }}>Received by:</p>
                                        <div >
                                            <h5 className='line-bor2 mb-3'>{incidentObj.receivedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>LDRRMO 1 Operations and Warning</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30 }}>Certified by:</p>
                                        <div >
                                            <h5 className='line-bor2 mb-3'>{incidentObj.submittedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>LDRRMO III</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </>

                </div>

            </div>
            {/* Atucal print */}


            <div
                ref={componentRef}
                id="printSection"
                style={{ visibility: "hidden" }}

            >
                {/* <h1 className='text-center'>Report Incident</h1> */}
                <div className='row'
                    style={{
                        padding: 5
                    }}
                >

                    <div style={{ height: '100vh' ,}} className='page__one'>
                        <div
                            className='col-md-12 text-center'>
                            <div className='col-md-12'>
                                <img className='immmg' style={{ width: '80%', height: 'auto',marginTop:'50px ' }} src={logoM} />
                            </div>
                            {/* <div className='col-md-4'>
                                <p>Province of Occidental Mindoro</p>
                                <p>Municipality of Magsaysay</p>
                                <b>Municipal Disaster Risk Reduction and Managment Office</b>
                            </div> */}

                        </div>


                        <>

                            <div className='form1'>
                                <h3 style={{ marginLeft: 300, padding: 10 }}>Incident Information</h3>
                                <div className='row d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Incident No:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor' style={{ marginLeft: 3 }}>
                                        {incidentObj.incidentNumber}

                                    </div>

                                </div>

                                {/* <div className='col-md-12 d-flex'> */}
                                <div className='row d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Name/Type of Incident:
                                        </label>

                                    </div>
                                    <div className='col-md-5 line-bor' style={{ marginLeft: 3 }}>
                                        {incidentObj.incidentType}
                                    </div>
                                </div>



                                {/* </div> */}

                                <div className='row d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Other (Please Indicate):
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor' style={{ marginLeft: 3 }}>
                                        {incidentObj.others}
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Place/Location of Incident:
                                            </label>
                                        </div>
                                        <div className='col-md-5 line-bor'>

                                            {incidentObj.locationofincident}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Date and time of Indcident
                                            </label>
                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.dateandtime}
                                        </div>
                                    </div>

                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                If Vehicular Accident, state the number and type of vehicle involved and plate number:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.involvedandplatenumber}


                                        </div>
                                    </div>


                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                If Other Incident, state the nature of Incident:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.natureofincident}
                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Name of Informant/Caller:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.informantName}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Time of call/Reported:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.timeofcall}

                                        </div>

                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Number of Patient/Casualty(if any):
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.numberofpatient}



                                        </div>
                                    </div>

                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Name/s of Patient/Casualty(if any):
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.nameofpatient}
                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Age:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.age}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Civil Status:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.civilStatus}



                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-1'>
                                        <div className='col-md-3'>
                                            <label>
                                                Address:
                                            </label>

                                        </div>
                                        <div className='col-md-5 line-bor'>
                                            {incidentObj.address}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='newform3'>
                                <h3 style={{ marginLeft: 200, padding: 15 }}>Emergency/Quick Response Team Deployment</h3>

                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time Deployed:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.timedeployed}
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time of Departure (Opcen):
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.timedepartureOpcen}
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time of Arrival (Location):
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>

                                        {incidentObj.timearrivalLocation}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time of Departure (Location):
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.timedepartureLocation}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time of Arrival (OpCen):
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.timearrivalOpcen}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Action taken by team:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.actiontakenbyteam}


                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Team Leader:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.teamleader}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Documenter:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.documenter}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Logistics:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.logistics}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Communication:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.communication}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Driver:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.driver}


                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Other responder on Scene:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.otherresponder}
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Vehicle Used:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.vehicleUsed}



                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-1'>
                                    <div className='col-md-3'>
                                        <label>
                                            Other Vehicle Used:
                                        </label>
                                    </div>
                                    <div className='col-md-5 line-bor'>
                                        {incidentObj.othervehicleUsed}


                                    </div>
                                </div>

                            </div>

                        </>


                        <p className='footer-line'>Note: Please see attached photos of the Incident and Other Documention such as Spot Report or Police Report for further information  </p>


                    </div>

                    <div style={{
                        height: '100vh',
                        padding: '5vh'


                    }} className="text-center">

                        <div
                            className='col-md-12 text-center'>
                            <div className='col-md-12 '>
                                <img style={{ width: '100%', height: 'auto', }} src={logoM} />
                            </div>
                            {/* <div className='col-md-4'>
                                <p>Province of Occidental Mindoro</p>
                                <p>Municipality of Magsaysay</p>
                                <b>Municipal Disaster Risk Reduction and Managment Office</b>
                            </div> */}

                        </div>


                        <br />
                        <br />
                        <div className='container'>
                            <div style={{ placeContent: 'center' }} className='row'>
                                {
                                    imgList && imgList.map(img => (
                                        <div style={{ border: '1px solid black', }} className='col-md-5 m-1'>
                                            <img style={{ height: 200, margin: 5 }} src={img} />
                                        </div>
                                    ))

                                }
                            </div>


                        </div>

                        <p className='footer-line2'>Note: Please see attached photos of the Incident and Other Documention such as Spot Report or Police Report for further information </p>
                    </div>


                    <div style={{ height: '100vh', padding: '5vh' }} className='print__three'>

                        <div
                            className='col-md-12 text-center'>
                            <div className='col-md-12 '>
                                <img style={{ width: '100%', height: 'auto' }} src={logoM} />
                            </div>
                            {/* <div className='col-md-4'>
                                <p>Province of Occidental Mindoro</p>
                                <p>Municipality of Magsaysay</p>
                                <b>Municipal Disaster Risk Reduction and Managment Office</b>
                            </div> */}

                        </div>

                        <div style={{ marginTop: 30 }} className='desc-box'>
                            <div className='desc-box-inner'>
                                DESCRIPTION OF INCIDENT
                            </div>


                            <h6 className='descri-text'>{incidentObj.description}</h6>
                        </div>
                        <div style={{ marginLeft: 200 }}>
                            <div style={{ margin: 10 }} className='d-flex'>
                                <div className='col-md-10 d-flex justify-content-around'>
                                    <br />
                                    <br />
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30, marginTop: '15px' }}>Prepared by:</p>
                                        <div >
                                            <h5 className='line-bor2'>{incidentObj.preparedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>Responder on Duty</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30, marginTop: '15px' }}>Submited by:</p>
                                        <div >
                                            <h5 className='line-bor2'>{incidentObj.submittedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>Team leader</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ margin: 10 }} className='row'>
                                <div className='col-md-10 d-flex justify-content-around'>

                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30, marginTop: '15px' }}>Received by:</p>
                                        <div >
                                            <h5 className='line-bor2'>{incidentObj.receivedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>LDRRMO 1 Operations and Warning</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <p style={{ marginBottom: 30, marginTop: '15px' }}>Certified by:</p>
                                        <div >
                                            <h5 className='line-bor2'>{incidentObj.submittedby}</h5>
                                            <h5>Signature over Printed Name</h5>
                                            <h5 className='fontbold'>LDRRMO III</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* <p className='footer-line3'>Note: Please see attached photos of the Incident and Other Documention such as Spot Report or Police Report for the further information  </p> */}

                    </div>

                </div>

            </div>

        </Paper >

    )
}

export default NewReportView