import React from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import BootstrapTable from "react-bootstrap-table-next";
import { useState, useEffect } from 'react';
import * as columnFormatters from "../../modules/ECommerce/pages/products/products-table/column-formatters";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import firebase from 'firebase';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';


const NewReportList = () => {

    const history = useHistory()

    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setdeleteModal] = useState(false)
    const [dispatchModal, setdispatchModal] = useState(false)
    const [selectedRoles, setSelectedRoles] = React.useState([])
    const [selectedObj, setselectedObj] = useState({})

    const [incidentsList, setincidentsList] = useState([])


    



    useEffect(() => {

        firebase.database().ref('newIncidents')
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    let arr = []
                    snapshot.forEach((value) => {
                        let item = value.val();
                        item.key = value.key;
                        arr.push(item);
                    })
                    // fireAlarm(arr)
                    setincidentsList(arr)

                }
            })


    }, [])
    const [title, settitle] = React.useState(selectedObj.title)


    const getTime = (date) => {
        let result = ''
        let d = new Date(date)

        let fd = new Intl.DateTimeFormat('en-us', {
            dateStyle: 'long',
            timeStyle: 'short'
        })

        result = fd.format(d)

        return result
    }
    const getDate = (timestamp) => {
        let dd = new Date(timestamp)

        let newd = new Intl.DateTimeFormat('en-us', {
            dateStyle: 'medium'
        })

        return newd.format(dd)
    }


    const columns = [
        {
            dataField: "incidentType",
            text: "Name",
            sort: true,

        },
        // {
        //     dataField: "description",
        //     text: "Description",
        //     sort: true,

        // },
        {
            dataField: "locationofincident",
            text: "Location",
            sort: true,

        },

        {
            dataField: "civilStatus",
            text: "Civil Status",
            sort: true,

        },
        {
            dataField: "address",
            text: "Address",
            sort: true,

        },
        {
            dataField: "dateandtime",
            text: "Date and Time",
            sort: true,
            formatter: (timestamp) => {
                return (
                    <span
                    // className={`label label-lg label-inline`}
                    >
                        {getTime(timestamp)}
                    </span>
                )
            }

        },

        {
            dataField: "action",
            text: "Actions",
            formatter: columnFormatters.ActionsColumnFormatterNew,
            formatExtraData: {
                openEditProductPage: (item) => openEditModal(item),
                openDeleteProductDialog: (row) => openDeleteModal(row),
                openDispatch: (row) => openDispatchModal(row),
                onViewClick: (row) => history.push(`/newreportview/${row.key}`),
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];


    const openDispatchModal = (item) => {
        setselectedObj(item)
        setdispatchModal(true)
    }


    const openEditModal = item => {
        setselectedObj(item)
        setEditModal(true)
        // console.log(item)
    }

    const openDeleteModal = item => {
        setselectedObj(item)
        setdeleteModal(true)
        // console.log(item)
    }


    const updateInc = () => {

        firebase.database().ref('incidents')
            .child(selectedObj.key)
            .update({
                title: title
            }).then(() => {
                toast.success('Updated Successfully')
                setEditModal(false)
            }).catch(err => {
                console.log(err)
                setEditModal(false)
            })

    }

    const deleteInc = () => {
        firebase.database().ref('newIncidents')
            .child(selectedObj.key)
            .remove()
            .then(() => {
                toast.success('Delete Successfully')
                setdeleteModal(false)
            }).catch(err => {
                console.log(err)
                setdeleteModal(false)
            })
    }



    return (
        <div>
            <Card>
                <CardHeader title="All Incidents">

                </CardHeader>
                <CardBody>

                    <BootstrapTable
                        wrapperClasses="table-responsive"
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        bordered={false}
                        remote
                        keyField="key"
                        data={incidentsList === null ? [] : incidentsList}
                        columns={columns}
                    >
                    </BootstrapTable>
                </CardBody>
            </Card>



            <Modal
                show={deleteModal}

            >
                <Modal.Header>
                    <Modal.Title>Delete Incident</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this incident?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setdeleteModal(false)}
                        className='btn btn-danger'>Cancel</button>
                    <button
                        onClick={deleteInc}
                        className='btn btn-primary'>Delete</button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default NewReportList