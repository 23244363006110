import React from 'react'
import firebase from 'firebase';
import accident from '../../assets/icons/accident.png'
import drought from '../../assets/icons/drought.png'
import earthquake from '../../assets/icons/earthquake.png'
import fireimg from '../../assets/icons/Fire.png'
import flood from '../../assets/icons/flood.png'
import Landslide from '../../assets/icons/landslide.png'
import medical_emg from '../../assets/icons/medical_emergency.png'
import police from '../../assets/icons/police.png'
import storm_surge from '../../assets/icons/storm_surge.png'
import tsunami from '../../assets/icons/tsunami.png'
import typhoon from '../../assets/icons/typhoon.png'
import './style.css'

const HomeList = () => {



    const getNumbers = (child) => {
        let result = 0;
        firebase.database().ref('incidents')
            .orderByChild('title')
            .equalTo(child)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    result = snapshot.numChildren()
                }
            })

        return result
    }


    return (
        <div className="card__2">
            <div className="icon__box">
                <img
                    style={{ height: 40, width: 40 }}
                    src={accident}
                />
                {/* <CarCrashIcon sx={{ fontSize: 40, color: '#F60000' }} /> */}
                <h3>{getNumbers('accidents')}</h3>
                <h5>Accident/s</h5>
            </div>
            <div className="icon__box">
                <img
                    style={{ height: 40, width: 40 }}
                    src={flood}
                />

                {/* <WavesIcon sx={{ fontSize: 40, color: '#B9A891' }} /> */}
                <h3>{getNumbers('floods')}</h3>
                <h5>Flood/s</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={typhoon} />

                {/* <CycloneIcon sx={{ fontSize: 40, color: '#242E39' }} /> */}
                <h3>{getNumbers('typhoons')}</h3>
                <h5>Typhoon/s</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={Landslide} />

                {/* <RemoveRoadIcon sx={{ fontSize: 40, color: '#DAB491' }} /> */}

                <h3>{getNumbers('landslides')}</h3>
                <h5>Landslide/s</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={fireimg} />

                {/* <LocalFireDepartmentIcon sx={{ fontSize: 40, color: '#F1471E' }} /> */}
                <h3>{getNumbers('fires')}</h3>
                <h5>Fire/s</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={police} />

                {/* <LocalPoliceIcon sx={{ fontSize: 40, color: '#362F7E' }} /> */}
                <h3>{getNumbers('police')}</h3>
                <h5>Police</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={storm_surge} />

                {/* <LocalPoliceIcon sx={{ fontSize: 40, color: '#362F7E' }} /> */}
                <h3>{getNumbers('storm surge')}</h3>
                <h5>Storm Surge</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={tsunami} />

                {/* <CarCrashIcon sx={{ fontSize: 40, color: '#F60000' }} /> */}
                <h3>{getNumbers('tsunami')}</h3>
                <h5>Tsunami</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40 }} src={drought} />

                {/* <RemoveRoadIcon sx={{ fontSize: 40, color: '#DAB491' }} /> */}
                <h3>{getNumbers('drought')}</h3>
                <h5>Drought</h5>
            </div>
            <div style={{ marginRight: 30 }} className="icon__box">
                <img style={{ height: 40, width: 40, }} src={medical_emg} />

                {/* <RemoveRoadIcon sx={{ fontSize: 40, color: '#DAB491' }} /> */}
                <h3>{getNumbers('medical emergency')}</h3>
                <h5 style={{ marginLeft: 30 }}>Medical Emergency</h5>
            </div>
            <div className="icon__box">
                <img style={{ height: 40, width: 40, }} src={earthquake} />
                {/* <RemoveRoadIcon sx={{ fontSize: 40, color: '#DAB491' }} /> */}
                <h3>{getNumbers('earthquake')}</h3>
                <h5>Earthquake</h5>
            </div>
        </div>
    )
}

export default HomeList