
const INITIAL_STATE = {
    hostels: [],

};

const HostelReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_HOSTELS':
            return {
                ...state,
                hostels: action.payload
            };

        default:
            return state;
    }
};

export default HostelReducer;
