import React from "react";
import { useState, useEffect } from "react";
import CustomMap from "../../../app/components/Map/CustomMap";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
import * as firebase from "firebase"
import HomeList from "../../../app/components/HomeList/HomeList";
import newsound from '../../../app/assets/newsound.mp3'
import DataTable from "../../../app/components/DataTable/DataTable";

export function Demo1Dashboard() {


  const [incidentsList, setincidentsList] = useState([])
  const [userDetails, setuserDetails] = useState({})


  useEffect(() => {

    firebase.database().ref('incidents')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          let arr = []
          snapshot.forEach((value) => {
            let item = value.val();
            item.key = value.key;
            arr.push(item);
          })
          // fireAlarm(arr)
          setincidentsList(arr)

        }
      })

  }, [])



  // useEffect(() => {
  //   firebase.database().ref('users')
  //     .child(firebase.auth().currentUser.uid)
  //     .on('value', snapshot => {
  //       if (snapshot.exists()) {
  //         setuserDetails(snapshot.val())
  //       }
  //     })

  // }, [])


  useEffect(() => {
    const onChildAdd = firebase.database()
      .ref('incidents')
      .orderByChild('timestamp')
      .startAt(Date.now())
      .on('child_added', snapshot => {
        if (snapshot.exists()) {
          console.log('new child aya h')
          let audd = new Audio(newsound)
          audd.play()

          setTimeout(() => {
            audd.pause()
          }, 20000);


        }

        // if (snapshot.val().title == 'fires') {
        //   let audio2 = new Audio(newsound)

        //   audio2.play()

        // } else if (snapshot.val().title == 'floods') {
        //   let audio3 = new Audio(newsound)

        //   audio3.play()


        // } else if (snapshot.val().title == 'typhoons') {

        //   let audio4 = new Audio(newsound)

        //   audio4.play()


        // } else if (snapshot.val().title == 'accidents') {
        //   let audio5 = new Audio(newsound)

        //   audio5.play()

        // }
        // else if (snapshot.val().title == 'landslides') {
        //   let audio6 = new Audio(newsound)

        //   audio6.play()

        // }
        // else if (snapshot.val().title == 'police') {
        //   let audio7 = new Audio(newsound)

        //   audio7.play()
        // }

      })

    // Stop listening for updates when no longer required
    return () => firebase.database().ref('/incidents').off('child_added', onChildAdd);

  }, [])



  return (
    <>
      <div className="row">

        <div className="col-lg-7 col-md-7 col-xxl-7">
          <CustomMap
            list={incidentsList}
          />
        </div>
        <div className="col-lg-5 col-md-5 col-xxl-5">
          <HomeList />
        </div>

        <div className="col-md-12 col-xl-12 col-xxl-12 col-sm-12 mt-3">
          <DataTable list={incidentsList} />
        </div>

        {/* <div className="col-lg-6 col-xxl-4">
          <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div> */}
        {/* 
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <ListsWidget1 className="card-stretch gutter-b" />
        </div> */}
        {/* <div className="col-xxl-8 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div> */}
        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div> */}
        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div> */}
        {/* <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-lg-4">
          <MixedWidget14 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div> */}
    </>
  );
}
