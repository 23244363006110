import { all } from "redux-saga/effects";
import { combineReducers } from "redux";


import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import CampusReducer from "./reducers/CampusReducer";
import UserReducer from "./reducers/UserReducer";
import StaffReducer from "./reducers/StaffReducer";
import HeadOfficeReducer from "./reducers/HeadOfficeReducer";
import BankReducer from "./reducers/BankReducer";
import VehiclesReducer from "./reducers/VehiclesReducer";
import HostelReducer from "./reducers/HostelReducer";
import StudentReducer from "./reducers/StudentReducer";
import ConcessionReducer from "./reducers/ConcessionReducer";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: UserReducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  concession:ConcessionReducer,
  campuses: CampusReducer,
  
  staff: StaffReducer,
  headoffice: HeadOfficeReducer,
  bank: BankReducer,
  vehicles: VehiclesReducer,
  hostel:HostelReducer,
  students:StudentReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
 
}

