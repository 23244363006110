
const INITIAL_STATE = {
    allstaff: [],

};

const StaffReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_STAFF':
            return {
                ...state,
                allstaff: action.payload
            };

        default:
            return state;
    }
};

export default StaffReducer;
