import React, { useState } from 'react'
import { Paper } from '@material-ui/core'
import { useFormik } from 'formik';
import * as yup from 'yup';
import './styles.css'
import { Card } from 'react-bootstrap'
import firebase from 'firebase';
import { toast } from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory } from 'react-router-dom';


const logo = `https://cdn-icons-png.flaticon.com/512/748/748113.png`

const validationSchema = yup.object({
    incidentNumber: yup
        .string('Enter your Indcident Number')
        .required('Number is required'),
    incidentType: yup
        .string('Select Indcident Type')
        .required('Incident Type is required'),
    others: yup
        .string('Select Indcident Type')
        .required('Indicate is required'),
    locationofincident: yup
        .string('Enter Location Please')
        .required('Location is required'),
    dateandtime: yup
        .string('Select Date and Time')
        .required('Date and time is required'),
    involvedandplatenumber: yup
        .string('Involved and Plate number')
        .required('Involved and Plate number is required'),
    natureofincident: yup
        .string('Enter nature of incident')
        .required('Nature of incident is required'),
    informantName: yup
        .string('Enter Informant Name ')
        .required('Informant is required'),
    timeofcall: yup
        .string('Select Time of call')
        .required('Time of call is required'),
    numberofpatient: yup
        .string('Enter Number of Patient')
        .required('Number of Patient is required'),
    nameofpatient: yup
        .string('Enter Name of Patient')
        .required('Name of Patient is required'),
    age: yup
        .string('Enter Age of Patient')
        .required('Age of Patient is required'),
    civilStatus: yup
        .string('Enter civil Status ')
        .required('civil Status is required'),
    address: yup
        .string('Enter address ')
        .required('Address is required'),

});

const IncidentList = [
    {
        id: 9,
        title: 'Fire',
        value: 'fire'
    },
    {
        id: 3,
        title: 'Police',
        value: 'police'
    },
    {
        id: 32,
        title: 'Floods',
        value: 'floods'
    },
    {
        id: 31,
        title: 'Typhoons',
        value: 'typhoons'
    },
    {
        id: 3112,
        title: 'Accidents',
        value: 'accidents'
    },
    {
        id: 123,
        title: 'Landslides',
        value: 'landslides'
    },
    {
        id: 324,
        title: 'Strom surge',
        value: 'Strom surge'
    },
    {
        id: 243,
        title: 'Tsunami',
        value: 'Tsunami'
    },
    {
        id: 63,
        title: 'Drought',
        value: 'Drought'
    },
    {
        id: 413,
        title: 'Medical Emergency',
        value: 'Medical Emergency'
    },
    {
        id: 392,
        title: 'Earthquake',
        value: 'earthquake'
    },

]




const ReportIncdent = () => {


    const history = useHistory()

    const [imagesList, setimagesList] = useState([])


    const initialValues = {
        incidentNumber: '',
        incidentType: '',
        others: '',
        locationofincident: '',
        dateandtime: '',
        involvedandplatenumber: '',
        natureofincident: '',
        informantName: '',
        timeofcall: '',
        numberofpatient: '',
        nameofpatient: '',
        age: '',
        civilStatus: '',
        address: '',
        timedeployed: '',
        timedepartureOpcen: '',
        timearrivalOpcen: '',
        timearrivalLocation: '',
        timedepartureLocation: '',
        actiontakenbyteam: '',
        teamleader: '',
        documenter: '',
        logistics: '',
        communication: '',
        driver: '',
        otherresponder: '',
        vehicleUsed: '',
        othervehicleUsed: '',
        description: '',
        preparedby: '',
        submittedby: '',
        receivedby: '',
        notedby: ''

    }



    const [currentStep, setcurrentStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [urlsList, seturlList] = useState([])

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleUpload(values, resetForm)
        }
    });



    const uploadImages = (values, resetForm) => {

        setLoading(true)
        const storageRef = firebase.storage().ref();
        let urls = []
        imagesList.forEach((img, i) => {
            console.log('image on by one', img.name)
            storageRef
                .child(`Incidentimages/${img.name}`)
                .put(img).then(async (snapshot) => {
                    await snapshot.ref.getDownloadURL().then((val) => {
                        urls.push(val)
                        seturlList((prevState) => [...prevState, val])

                    })
                }).catch(err => {
                    console.log(err)
                })


            if (i + 1 == imagesList.length) {
                console.log('last hogya?')
                savetoDb(values, resetForm, urls)
            }
        })

        // if(imagesList.length == urls.length){

        // }

        // let promise = []
        // let list = []
        // imagesList.map((img) => {
        //     const uploadTask = firebase.storage().ref(`images/${img.name}`).put(img)
        //     promise.push(uploadTask)
        //     uploadTask.on('state_changed', (snapshot) => {
        //         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

        //     }, (error) => {
        //         console.log(error)
        //     },
        //         async () => {
        //             await firebase.storage().ref('images')
        //                 .child(img.name)
        //                 .getDownloadURL()
        //                 .then(urls => {
        //                     // console.log(urls)
        //                     // list.push(urls)
        //                     seturlList((prevState) => [...prevState, urls])

        //                 })
        //         }
        //     )
        // })

        // Promise.all(promise)
        //     .then(() => {
        //         console.log('uploaded successfully')
        //         // savetoDb(values, resetForm)
        //         // console.log(list)
        //     }).catch(err => {
        //         console.log('promise error', err)
        //     })


    }


    // new code from chat gpt

    const handleUpload = async (values, resetForm) => {
        setLoading(true)
        const promises = [];

        for (let i = 0; i < imagesList.length; i++) {
            const file = imagesList[i];
            const uploadTask = firebase.storage().ref(`InciImages/${file.name}`).put(file);
            promises.push(uploadTask);
        }

        Promise.all(promises).then(async () => {
            console.log('Uploaded all files');
            const urls = [];
            for (let i = 0; i < imagesList.length; i++) {
                const url = await getDownloadURL(imagesList[i]);
                urls.push(url);
            }
            console.log('Download URLs:', urls);

            values['imagesList'] = urls

            await firebase.database().ref('newIncidents')
                .push(values)
                .then(() => {
                    setLoading(false)
                    toast.success(`Incident Reported successfully`)
                    // formik.resetForm()
                    resetForm()
                    history.push('/newincidentlist')
                    // setimagesList([])
                    // seturlList([])
                }).catch(err => {
                    setLoading(false)
                    toast.error(err.message)
                })
            //   firebase.database().ref('images/').set(urls);
        });
    }



    const getDownloadURL = async (file) => {
        const url = await firebase.storage().ref(`InciImages/${file.name}`).getDownloadURL();
        return url;
    }

    const savetoDb = async (values, resetForm, imgs) => {

        console.log('save to db', imgs)

        values['imagesList'] = imgs

        await firebase.database().ref('newIncidents')
            .push(values)
            .then(() => {
                setLoading(false)
                toast.success(`Incident Reported successfully`)
                // formik.resetForm()
                resetForm()
                history.push('/newincidentlist')
                // setimagesList([])
                // seturlList([])
            }).catch(err => {
                setLoading(false)
                toast.error(err.message)
            })
        // console.log(urlsList)

    }

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };


    const gotToNext = () => {

        if (currentStep == 3 && imagesList.length < 6) {
            toast.error('Please select six images at least')

        } else {
            setcurrentStep(currentStep + 1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })

        }
    }

    const gotToPrevious = () => {
        setcurrentStep(currentStep - 1)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }


    const handleChange = img => {
        let arr = [...imagesList]
        arr.push(img)
        setimagesList(arr)
    }


    const removeImage = (img, index) => {
        let arr = [...imagesList]
        const filterd = arr.filter(item => item !== img)
        setimagesList(filterd)
    }



    return (
        <Paper style={{ backgroundColor: '#cc7000' }} className='p-4'>
            <h1 className='ml-2'>Report Incident</h1>
            <form onSubmit={formik.handleSubmit}>
                {/* form one  */}
                {
                    currentStep == 1 ?
                        <div className='form1'>
                            <div className='col-md-6 d-flex m-2'>
                                <div className='col-md-6'>
                                    <label>
                                        Incident No:
                                    </label>

                                </div>
                                <div className='col-md-6'>
                                    <input
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "incidentNumber"
                                        )}`}
                                        name='incidentNumber'
                                        placeholder='No. of Incident'
                                        {...formik.getFieldProps("incidentNumber")}
                                    />

                                    {formik.touched.incidentNumber && formik.errors.incidentNumber ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="text-danger">{formik.errors.incidentNumber}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div className='numberinput'>
                                <div className='col-md-2'>
                                    <label>
                                        Incident No:
                                    </label>
                                </div>
                                <div className='col-md-3'>
                                    <input
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "incidentNumber"
                                        )}`}
                                        name='incidentNumber'
                                        placeholder='No. of Incident'
                                        {...formik.getFieldProps("incidentNumber")}
                                    />

                                    {formik.touched.incidentNumber && formik.errors.incidentNumber ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="text-danger">{formik.errors.incidentNumber}</div>
                                        </div>
                                    ) : null}
                                </div>

                            </div> */}
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='col-md-6 d-flex m-2'>
                                        <div className='col-md-6'>
                                            <label>
                                                Name/Type of Incident:
                                            </label>

                                        </div>
                                        <div className='col-md-6'>
                                            <select
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "incidentType"
                                                )}`}
                                                name='incidentType'

                                                {...formik.getFieldProps('incidentType')}
                                            >
                                                <option value={''}>Select</option>
                                                {
                                                    IncidentList.map(op => (
                                                        <option key={op.id} value={op.value}>{op.title}</option>
                                                    ))
                                                }
                                            </select>

                                            {formik.touched.incidentType && formik.errors.incidentType ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.incidentType}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>


                                    <div className='col-md-6 d-flex m-2'>
                                        <div className='col-md-6'>
                                            <label>
                                                Other (Please Indicate):
                                            </label>
                                        </div>
                                        <div className='col-md-10'>
                                            <input
                                                name='other'
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "others"
                                                )}`}
                                                placeholder='others'
                                                {...formik.getFieldProps('others')}
                                            />

                                            {formik.touched.others && formik.errors.others ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.others}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-md-12 inputcon d-flex m-2'>
                                    <div className='col-md-3'>
                                        <label>
                                            Place/Location of Incident:
                                        </label>
                                    </div>
                                    <div className='col-md-6'>
                                        <textarea
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "locationofincident"
                                            )}`}
                                            name='locationofincident'
                                            placeholder='Place/Location of Incident'
                                            {...formik.getFieldProps("locationofincident")}
                                        />

                                        {formik.touched.locationofincident && formik.errors.locationofincident ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.locationofincident}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 inputcon d-flex m-2'>
                                    <div className='col-md-3'>
                                        <label>
                                            Date and time of Incident:
                                        </label>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            name='dateandtime'
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "dateandtime"
                                            )}`}
                                            placeholder='date and time'
                                            type={'datetime-local'}
                                            {...formik.getFieldProps('dateandtime')}
                                        />

                                        {formik.touched.dateandtime && formik.errors.dateandtime ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.dateandtime}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>

                                <div className='col-md-12 inputcon d-flex m-2'>
                                    <div className='col-md-3'>
                                        <label>
                                            If Vehicular Accident, state the number and type of vehicle involved and plate number:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <textarea
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "involvedandplatenumber"
                                            )}`}
                                            name='involvedandplatenumber'
                                            placeholder='Place/Location of Incident'
                                            {...formik.getFieldProps("involvedandplatenumber")}
                                        />

                                        {formik.touched.involvedandplatenumber && formik.errors.involvedandplatenumber ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.involvedandplatenumber}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>


                                <div className='col-md-12 d-flex m-2'>
                                    <div className='col-md-3'>
                                        <label>
                                            If Other Incident, state the nature of Incident:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <textarea
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "natureofincident"
                                            )}`}
                                            name='natureofincident'
                                            placeholder='Nature of Incident'
                                            {...formik.getFieldProps("natureofincident")}
                                        />

                                        {formik.touched.natureofincident && formik.errors.natureofincident ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.natureofincident}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 inputcon d-flex m-2'>
                                    <div className='col-md-3'>
                                        <label>
                                            Name of Informant/Caller:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "informantName"
                                            )}`}
                                            name='informantName'
                                            placeholder='Name of Informant/Caller'
                                            {...formik.getFieldProps("informantName")}
                                        />

                                        {formik.touched.informantName && formik.errors.informantName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.informantName}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-2 inputcon'>
                                    <div className='col-md-3'>
                                        <label>
                                            Time of call/Reported:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "timeofcall"
                                            )}`}
                                            name='timeofcall'
                                            placeholder='Time of call'
                                            {...formik.getFieldProps("timeofcall")}
                                        />

                                        {formik.touched.timeofcall && formik.errors.timeofcall ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.timeofcall}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-2 inputcon'>
                                    <div className='col-md-3'>
                                        <label>
                                            Number of Patient/Casualty(if any):
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "numberofpatient"
                                            )}`}
                                            name='numberofpatient'
                                            placeholder='Number of Patient'
                                            {...formik.getFieldProps("numberofpatient")}
                                        />

                                        {formik.touched.numberofpatient && formik.errors.numberofpatient ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.numberofpatient}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>

                                <div className='col-md-12 d-flex m-2 inputcon'>
                                    <div className='col-md-3'>
                                        <label>
                                            Name/s of Patient/Casualty(if any):
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "nameofpatient"
                                            )}`}
                                            name='nameofpatient'
                                            placeholder='Name/s of Patient'
                                            {...formik.getFieldProps("nameofpatient")}
                                        />

                                        {formik.touched.nameofpatient && formik.errors.nameofpatient ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.nameofpatient}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-2 inputcon'>
                                    <div className='col-md-3'>
                                        <label>
                                            Age:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "age"
                                            )}`}
                                            name='age'
                                            placeholder='Enter age'
                                            {...formik.getFieldProps("age")}
                                        />

                                        {formik.touched.age && formik.errors.age ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.age}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-2 inputcon'>
                                    <div className='col-md-3'>
                                        <label>
                                            Civil Status:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "civilStatus"
                                            )}`}
                                            name='civilStatus'
                                            placeholder='Civil Status'
                                            {...formik.getFieldProps("civilStatus")}
                                        />

                                        {formik.touched.civilStatus && formik.errors.civilStatus ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.civilStatus}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                <div className='col-md-12 d-flex m-2 inputcon'>
                                    <div className='col-md-3'>
                                        <label>
                                            Address:
                                        </label>

                                    </div>
                                    <div className='col-md-6'>
                                        <textarea
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "address"
                                            )}`}
                                            name='address'
                                            placeholder='Enter Address'
                                            {...formik.getFieldProps("address")}
                                        />

                                        {formik.touched.address && formik.errors.address ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="text-danger">{formik.errors.address}</div>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        currentStep == 3 ?
                            <div>
                                <div style={{
                                    // display:'flex'
                                    alignItems: 'center'
                                }}>
                                    <h3 className='text-success text-emg'>Please upload Image's</h3>
                                    <span style={{ marginLeft: 10 }} className='text-warning'>
                                        Maximum six images allowed</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        imagesList.length == 6 ?
                                            null
                                            :
                                            <label
                                                htmlFor='fileImage'
                                                style={{
                                                    width: 130,
                                                    margin: 20,
                                                    padding: 10,
                                                    borderRadius: 5,
                                                    backgroundColor: '#E6E9EC',
                                                    cursor: 'pointer'
                                                }}>
                                                <img style={{ width: 100 }} src={logo} />
                                            </label>

                                    }
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        id={`fileImage`}
                                        // ref={fileRef}
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => handleChange(e.target.files[0])}
                                    />
                                    {
                                        imagesList.map((img, index) => (
                                            <div
                                                style={{ width: 120, height: 120, margin: 5 }}
                                            >
                                                <img
                                                    src={URL.createObjectURL(img)}
                                                    style={{ width: 120, height: 120 }}
                                                />
                                                <button onClick={() => removeImage(img, index)} className='delete-btn'>
                                                    <svg
                                                        style={{ color: 'red' }}
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            currentStep == 2 ?

                                <div className='form3'>
                                    <h3 className='text-success text-emg'>Emergency/Quick Response Team Deployment</h3>

                                    <div className='col-md-12 inputcon d-flex m-2'>
                                        <div className='col-md-3'>
                                            <label>
                                                Time Deployed:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'time'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "timedeployed"
                                                )}`}
                                                name='timedeployed'
                                                placeholder='Place/Location of Incident'
                                                {...formik.getFieldProps("timedeployed")}
                                            />

                                            {formik.touched.timedeployed && formik.errors.timedeployed ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.timedeployed}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 inputcon d-flex m-2'>
                                        <div className='col-md-3'>
                                            <label>
                                                Time of Departure (Opcen):
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'time'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "timedepartureOpcen"
                                                )}`}
                                                name='timedepartureOpcen'
                                                placeholder='Place/Location of Incident'
                                                {...formik.getFieldProps("timedepartureOpcen")}
                                            />

                                            {formik.touched.timedepartureOpcen && formik.errors.timedepartureOpcen ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.timedepartureOpcen}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Time of Arrival (Location):
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'time'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "timearrivalLocation"
                                                )}`}
                                                name='timearrivalLocation'
                                                placeholder='Place/Location of Incident'
                                                {...formik.getFieldProps("timearrivalLocation")}
                                            />

                                            {formik.touched.timearrivalLocation && formik.errors.timearrivalLocation ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.timearrivalLocation}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Time of Departure (Location):
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'time'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "timedepartureLocation"
                                                )}`}
                                                name='timedepartureLocation'
                                                placeholder='Place/Location of Incident'
                                                {...formik.getFieldProps("timedepartureLocation")}
                                            />

                                            {formik.touched.timedepartureLocation && formik.errors.timedepartureLocation ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.timedepartureLocation}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Time of Arrival (OpCen):
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'time'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "timearrivalOpcen"
                                                )}`}
                                                name='timearrivalOpcen'
                                                placeholder='Place/Location of Incident'
                                                {...formik.getFieldProps("timearrivalOpcen")}
                                            />

                                            {formik.touched.timearrivalOpcen && formik.errors.timearrivalOpcen ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.timearrivalOpcen}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Action taken by team:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "actiontakenbyteam"
                                                )}`}
                                                name='actiontakenbyteam'
                                                placeholder='Action taken by team'
                                                {...formik.getFieldProps("actiontakenbyteam")}
                                            />

                                            {formik.touched.actiontakenbyteam && formik.errors.actiontakenbyteam ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.actiontakenbyteam}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Team Leader:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "teamleader"
                                                )}`}
                                                name='teamleader'
                                                placeholder='Team leader'
                                                {...formik.getFieldProps("teamleader")}
                                            />

                                            {formik.touched.teamleader && formik.errors.teamleader ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.teamleader}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Documenter:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "documenter"
                                                )}`}
                                                name='documenter'
                                                placeholder='Documenter'
                                                {...formik.getFieldProps("documenter")}
                                            />

                                            {formik.touched.documenter && formik.errors.documenter ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.documenter}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Logistics:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "logistics"
                                                )}`}
                                                name='logistics'
                                                placeholder='Logistics'
                                                {...formik.getFieldProps("logistics")}
                                            />

                                            {formik.touched.logistics && formik.errors.logistics ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.logistics}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Communication:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "communication"
                                                )}`}
                                                name='communication'
                                                placeholder='Communication'
                                                {...formik.getFieldProps("communication")}
                                            />

                                            {formik.touched.communication && formik.errors.communication ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.communication}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Driver:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "driver"
                                                )}`}
                                                name='driver'
                                                placeholder='Driver'
                                                {...formik.getFieldProps("driver")}
                                            />

                                            {formik.touched.driver && formik.errors.driver ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.driver}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Other responder on Scene:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "otherresponder"
                                                )}`}
                                                name='otherresponder'
                                                placeholder='Other responder on Scene'
                                                {...formik.getFieldProps("otherresponder")}
                                            />

                                            {formik.touched.otherresponder && formik.errors.otherresponder ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.otherresponder}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Vehicle Used:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "vehicleUsed"
                                                )}`}
                                                name='vehicleUsed'
                                                placeholder='Vehicle Used'
                                                {...formik.getFieldProps("vehicleUsed")}
                                            />

                                            {formik.touched.vehicleUsed && formik.errors.vehicleUsed ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.vehicleUsed}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>
                                    <div className='col-md-12 d-flex m-2 inputcon'>
                                        <div className='col-md-3'>
                                            <label>
                                                Other Vehicle Used:
                                            </label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type={'text'}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "othervehicleUsed"
                                                )}`}
                                                name='Other Vehicle Used'
                                                placeholder='Place/Location of Incident'
                                                {...formik.getFieldProps("othervehicleUsed")}
                                            />

                                            {formik.touched.othervehicleUsed && formik.errors.othervehicleUsed ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="text-danger">{formik.errors.othervehicleUsed}</div>
                                                </div>
                                            ) : null}

                                        </div>
                                    </div>

                                </div>

                                :

                                currentStep == 4 ?
                                    <div className='form4'>
                                        <div className='col-md-12 d-flex m-2'>
                                            <div className='col-md-3'>
                                                <label>
                                                    Description:
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <textarea
                                                    type={'text'}
                                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                        "description"
                                                    )}`}
                                                    name='description'
                                                    placeholder='Description'
                                                    {...formik.getFieldProps("description")}
                                                />

                                                {formik.touched.description && formik.errors.description ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="text-danger">{formik.errors.description}</div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                        <div className='col-md-12 d-flex m-2'>
                                            <div className='col-md-3'>
                                                <label>
                                                    Prepared by:
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type={'text'}
                                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                        "preparedby"
                                                    )}`}
                                                    name='preparedby'
                                                    placeholder='Prepared by'
                                                    {...formik.getFieldProps("preparedby")}
                                                />

                                                {formik.touched.preparedby && formik.errors.preparedby ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="text-danger">{formik.errors.preparedby}</div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                        <div className='col-md-12 d-flex m-2 inputcon'>
                                            <div className='col-md-3'>
                                                <label>
                                                    Submitted by:
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type={'text'}
                                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                        "submittedby"
                                                    )}`}
                                                    name='submittedby'
                                                    placeholder='Submitted by'
                                                    {...formik.getFieldProps("submittedby")}
                                                />

                                                {formik.touched.submittedby && formik.errors.submittedby ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="text-danger">{formik.errors.submittedby}</div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                        <div className='col-md-12 d-flex m-2'>
                                            <div className='col-md-3'>
                                                <label>
                                                    Noted by:
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type={'text'}
                                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                        "notedby"
                                                    )}`}
                                                    name='notedby'
                                                    placeholder='Noted by'
                                                    {...formik.getFieldProps("notedby")}
                                                />

                                                {formik.touched.notedby && formik.errors.notedby ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="text-danger">{formik.errors.notedby}</div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                        <div className='col-md-12 d-flex m-2 inputcon'>
                                            <div className='col-md-3'>
                                                <label>
                                                    Received by:
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type={'text'}
                                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                        "receivedby"
                                                    )}`}
                                                    name='receivedby'
                                                    placeholder='Received by'
                                                    {...formik.getFieldProps("receivedby")}
                                                />

                                                {formik.touched.receivedby && formik.errors.receivedby ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="text-danger">{formik.errors.receivedby}</div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                    </div>

                                    :

                                    null

                }
                {/* {
                    loading ?

                        <span className='text-info'>Saving your info please wait </span>
                        :
                        currentStep == 4 ?
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end'
                                }}
                                className='col-md-12 col-12'>
                                <button type='submit' className="btn btn-primary">Submit</button>
                            </div>
                            : null
                } */}

                {
                    currentStep == 4 ?
                        <div
                            className='text-center'>
                            {
                                loading ?

                                    <p className='text-info'>Saving your info please wait </p>
                                    :
                                    <button disabled={loading ? true : false} type='submit' className="btn btn-primary w-50">Submit</button>

                            }
                        </div>
                        : null
                }



            </form>

            <div
                style={{
                    margin: 10,
                    marginTop: 50,
                    display: 'flex',
                    justifyContent: 'space-around'
                }}
                className='text-center'>
                {
                    currentStep == 1 ?
                        null :

                        <button disabled={currentStep == 1 ? true : false} onClick={gotToPrevious} className="previous but2">&laquo; Previous</button>
                }

                {
                    currentStep == 4 ?
                        null :
                        <button disabled={currentStep == 4 ? true : false} onClick={gotToNext} className="next but2">Next &raquo;</button>
                }


            </div>

            {/* </Card> */}
        </Paper>


    )
}

export default ReportIncdent